import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import media from '../../../utils/media'

const Container = styled.div`
  width: 100vw;
  background-color: ${({ theme }) => get(theme, 'white', '#FFF')};
  display: flex;
  visibility: ${({ shouldDisplay }) => shouldDisplay ? 'visible' : 'hidden'};
  opacity: ${({ shouldDisplay }) => shouldDisplay ? 1 : 0};
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  transition: .5s opacity ease,.5s visibility ease;
  padding: 14px;
  z-index: 99999;
  border-top: 0.5px solid ${({ theme }) => get(theme, 'grey', '#FFF')};
`

const Button = styled.div`
  display: flex;
  font-family: 'Source Sans Pro';
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => get(theme, 'brightOrange', '#fff')};
  border-radius: 25px;
  padding: 11px 25px;
  color: ${({ theme }) => get(theme, 'white', '#FFF')};
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`

const StyledIcon = styled(FontAwesomeIcon)`
  margin-left: 16px;

  ${media.lessThan('lg')`
    margin-left: 8px;
  `}
`

const IndependantAcquisitionMobileButton = ({ hiddenRef, label }) => {
  const [isVisible, updateIsVisible] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler)
   
    return () => window.removeEventListener('scroll', scrollHandler)
  }, [isVisible])
  
  const scrollHandler = useCallback(() => {
    if (isVisible && window.pageYOffset + window.innerHeight < hiddenRef.current.offsetTop) {
      updateIsVisible(false)
    }

    if (!isVisible && window.pageYOffset + window.innerHeight >= hiddenRef.current.offsetTop) {
      updateIsVisible(true)
    }
  }, [isVisible])

  const scrollToView = useCallback(() => {
    hiddenRef.current.scrollIntoView({ behavior: 'smooth' })
  }, [hiddenRef])

  return (
    <Container shouldDisplay={!isVisible}>
      <Button onClick={scrollToView}>
        {label} <StyledIcon icon='chevron-right' />
      </Button>
    </Container>
  )
}

IndependantAcquisitionMobileButton.propTypes = {
  label: PropTypes.string,
  hiddenRef: PropTypes.object.isRequired,
}

IndependantAcquisitionMobileButton.defaultProps = {
  label: 'Envoyer'
}

export default IndependantAcquisitionMobileButton
