import React from 'react'
import styled from 'styled-components'
import { get, isEqual } from 'lodash'

import media from '../../../utils/media'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${media.greaterThan('lg')`
    max-width: 368px;
  `}
`

const Title = styled.p`
  font-family: 'Source Sans Pro';
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  color: ${({ theme }) => get(theme, 'blue')};

  ${media.lessThan('sm')`
    font-size: 15px;
  `}
    ${media.lessThan( 'lg' )`
    margin-top: 8px;
  `}
`

const Subtitle = styled(Title)`
  font-weight: normal;

  ${media.lessThan('sm')`
    font-size: 14px;
  `}

  ${media.lessThan( 'lg' )`
    margin-top: 0;
  `}
`

const IndependantAcquisitionDescription = ({ city, type }) => {
  return (
    <Container>
      <Title>
        Nous référençons tous les locaux professionnels à vendre en France.
      </Title>
      <Subtitle>
        Dites nous en plus sur votre projet pour identifier le bien qui peut vous correspondre
      </Subtitle>
    </Container>
  )
}

export default IndependantAcquisitionDescription
