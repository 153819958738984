import React from 'react'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import { useStaticQuery, graphql } from 'gatsby'
import { get, nth, first, isEqual } from 'lodash'

import Logo from '../../atoms/Logo'
import media from '../../../utils/media'
import numeral from '../../../config/number'

const StyledImageSection = styled(BackgroundImage)`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  min-height: 440px;

  ${media.greaterThan('lg')`
    height: 100vh;
    position: fixed; 
    overflow-y: auto;
    top: 0; right: 0; bottom: 0; left: 0;
  `}

  ${media.lessThan('lg')`
    margin-bottom: 24px;
  `}
`

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${media.greaterThan('lg')`
    padding-top: 55px;
  `}
`

const Title = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: 'Source Sans Pro';
  font-size: 32px;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: bold;
  color: ${ ( { theme } ) => get( theme, 'blue' ) };
  background:  ${ ( { theme } ) => get( theme, 'white' ) };
  padding: 16px 24px 24px;
  margin: 0 auto;
  border-radius: 25px;
  gap: 8px;
  max-height: 300px;

  ${media.lessThan('xl')`
    font-size: 28px;
  `}

  ${media.lessThan('lg')`
    display: none;
  `}
`

const LogoContainer = styled.div`
  width: 100%;

  & > a {
    display: block;
    margin: auto;
    width: 160px;
  }
`

const StyledLogo = styled( Logo )`
  width: 100%;
  height: 100%;
`

const DataItem = styled.div`
  max-width: 268px;
  margin-bottom: 8px;
  border-radius: 15px;
  padding: 11px 16px;
  background-color: ${ ( { theme } ) => get( theme, 'white', '#FFF' ) };
`

const DataText = styled.p`
  font-family: 'Source Sans Pro';
  font-size: 18px;
  font-weight: 600;
  line-height: 1.3em;
  color: ${ ( { theme } ) => get( theme, 'blue' ) };

  ${ media.lessThan( 'lg' )`
    font-size: 16px;
    line-height: 1.19;
  `}

  & > b {
    color: ${ ( { theme } ) => get( theme, 'brightOrange' ) };
  }

  & > span {
    font-size: 16px;
    font-weight: normal;

    ${ media.lessThan( 'lg' )`
      font-size: 14px;
    `}
  }
`

const BackgroundText = ( { type, price } ) =>
{
  return (
    <DataItem>
      <DataText>
        Locaux de 2 à 6 pièces<br />
        <b>À partir de 160 000€</b><br />
        <span>Centre Ville, Accès PMR, Parking</span>
      </DataText>
    </DataItem>
  )
}

const IndependantAcquisitionBackground = ( { id, price, type } ) =>
{
  const { placeholderImages, independantImages } = useStaticQuery( graphql`{
    placeholderImages: allFile(
      filter: { relativeDirectory: { eq: "programs-acquisition-independant" } }
      sort: { fields: relativePath }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            layout: FULL_WIDTH
          )
        }
      }
    }
  }`)

  const dataImages = isEqual( type, 'Independant' ) ? independantImages : placeholderImages
  const image = getImage( nth( get( dataImages, 'nodes' ), id - 1 ) || first( get( dataImages, 'nodes' ) ) )
  const bgImage = convertToBgImage( image )

  return (
    <StyledImageSection
      alt='Programmes immobiliers neufs'
      Tag='div'
      {...bgImage}
      preserveStackingContext>
      <Title>
        <LogoContainer>
          <StyledLogo
            blue={true}
            classic={true} />
        </LogoContainer>
        Locaux Professions Libérales
      </Title>
      <DataContainer>
        <DataItem>
          <DataText>
            Spécialiste de l’immobilier pour les libéraux
          </DataText>
        </DataItem>
        <BackgroundText type={type} price={price} />
      </DataContainer>
    </StyledImageSection>
  )
}

export default IndependantAcquisitionBackground
