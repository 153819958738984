import React from 'react'
import styled from 'styled-components'
import media from '../../../utils/media'

import IndependantSidebarForm from '../../molecules/IndependantSidebarForm'
import IndependantAcquisitionTitle from '../../molecules/IndependantAcquisitionTitle'
import IndependantAcquisitionBackground from '../../molecules/IndependantAcquisitionBackground'
import IndependantAcquisitionDescription from '../../molecules/IndependantAcquisitionDescription'

const SUBMIT = 'https://hooks.zapier.com/hooks/catch/2114713/3dqmghh'

const Container = styled.div`
  display: flex;
`

const FormSection = styled.div`
  padding: 32px 40px;
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${media.lessThan('md')`
  display: none;
  `}
`

const IndependantAcquisitionDesktop = ({ id, city, price, type }) => (
  <Container>
    <IndependantAcquisitionBackground
      id={id}
      type={type}
      price={price} />
    <FormSection>
      <IndependantAcquisitionTitle city={city} type={type} />
      <IndependantAcquisitionDescription city={city} type={type} />
      <IndependantSidebarForm
        city={city}
        submit={SUBMIT} />
    </FormSection>
  </Container>
)

export default IndependantAcquisitionDesktop
