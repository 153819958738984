import React, { useRef } from 'react'
import styled from 'styled-components'
import { get } from 'lodash'
import { graphql, useStaticQuery } from 'gatsby'

import SEO from '../../components/atoms/Seo'
import media from '../../components/../utils/media'
import JsonLD from '../../components/atoms/JsonLD'
import numeral from '../../components/../config/number'
import IndependantAcquisitionMobile from '../../components/organisms/IndependantAcquisitionMobile'
import IndependantAcquisitionDesktop from '../../components/organisms/IndependantAcquisitionDesktop'
import IndependantAcquisitionMobileButton from '../../components/organisms/IndependantAcquisitionMobileButton'
import { deepReplace, replaceGraphKey } from '../../components/../utils/deepReplace'

const DesktopContainer = styled.div`
  display: block;

  ${media.lessThan('lg')`
    display:none;
  `}
`

const MobileContainer = styled.div`
  display: block;

  ${media.greaterThan('lg')`
    display:none;
  `}
`

const ProgramsAcquisitionPage = () => {
  const hiddenRef = useRef()
  const type = ''
  const city = ''
  const price = 160000
  const zipcode = ''

  const { site } = useStaticQuery(graphql`
      query {
        site {
          siteMetadata {
            webpage {
              _context
              _type
              name
              url
              relatedLink
              specialty
            }
            organization {
              _context
              _type
              _id
              url
              image
              geo {
                _type
                latitude
                longitude
              }
              address {
                _type
                streetAddress
                addressLocality
                postalCode
                addressCountry {
                  _type
                  name
                }
              }
              name
              knowsAbout
            }
          }
        }
      }
    `)
  const { webpage, organization } = get(deepReplace(site, replaceGraphKey), 'siteMetadata')

  const title = 'Locaux professions libérales'
  const description = `Tous les locaux à vendre du 2 au 6 pièces à partir de ${numeral(price).format('0,0 $')}.`

  const keywords = ['Folhomee', 'Programme', 'Neuf', 'Immobilier', 'Appartement', 'Locaux', 'Libéral', 'Professionnel']
  const linkedData = {
    '@context': 'http://schema.org',
    '@type': 'Project',
    name: `Folhomee ${city}`,
    description: description,
    parentOrganization: {
      '@type': 'Organization',
      name: 'Folhomee',
      location: {
        '@type': 'PostalAddress',
        streetAddress: '44 Rue du Chemin Vert',
        addressLocality: 'Paris',
        postalCode: '75011'
      }
    },
    keywords: keywords.join(', '),
    makesOffer: {
      '@type': 'Offer',
      price: price,
      priceCurrency: 'EUR'
    },
    address: {
      '@type': 'PostalAddress',
      addressLocality: city,
      postalCode: zipcode
    }
  }

  return (
    <>
      <SEO
        title={title}
        description={description}>
          <JsonLD>
            {[webpage, organization, linkedData]}
          </JsonLD>
        </SEO>
      <DesktopContainer>
        <IndependantAcquisitionDesktop
          id={1}
          type={type}
          city={city}
          price={price} />
      </DesktopContainer>
      <MobileContainer>
        <IndependantAcquisitionMobileButton hiddenRef={hiddenRef}/>
        <IndependantAcquisitionMobile
          id={1}
          type={type}
          city={city}
          price={price}
          hiddenRef={hiddenRef} />
      </MobileContainer>
    </>
  )
}

export default ProgramsAcquisitionPage
