import React from 'react'
import styled from 'styled-components'

import Logo from '../../atoms/Logo'
import media from '../../../utils/media'
import IndependantSidebarForm from '../../molecules/IndependantSidebarForm'
import IndependantAcquisitionTitle from '../../molecules/IndependantAcquisitionTitle'
import IndependantAcquisitionDescription from '../../molecules/IndependantAcquisitionDescription'
import IndependantAcquisitionBackground from '../../molecules/IndependantAcquisitionBackground'

const SUBMIT = 'https://hooks.zapier.com/hooks/catch/2114713/3dqmghh'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const TopSection = styled.div`
  padding: 24px 72px;

  ${media.lessThan('md')`
    padding: 24px 40px;
  `}

  ${media.lessThan('sm')`
    padding: 24px;
  `}
`

const LogoContainer = styled.div`
  margin-bottom: 32px;
  max-width: 200px;
  ${media.greaterThan('lg')`
    display: none;
  `}

  & > a > div {
    width: 200px;
  }
`

const FormSection = styled.div`
  padding: 0 32px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ProgramsAcquisitionMobile = ({ id, city, price, type, hiddenRef }) => (
  <Container>
    <TopSection>
      <LogoContainer>
        <Logo classic={true} />
      </LogoContainer>
      <IndependantAcquisitionTitle city={city} />
      <IndependantAcquisitionDescription city={city} type={type} />
    </TopSection>
    <IndependantAcquisitionBackground
      id={id}
      type={type}
      price={price} />
    <FormSection>
      <IndependantSidebarForm
        city={city}
        submit={SUBMIT}
        hiddenRef={hiddenRef} />
    </FormSection>
  </Container>
)

export default ProgramsAcquisitionMobile
