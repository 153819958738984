import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'

import media from '../../../utils/media'

const Container = styled.h1`
  display: flex;
  flex-direction: column;
  gap: 4px;

  ${media.greaterThan('lg')`
    max-width: 325px;
  `}

  ${media.lessThan('lg')`
    gap: 8px;
  `}
`

const TitleText = styled.span`
  font-family: 'Source Sans Pro';
  font-size: 20px;
  font-weight: bold;
  color: ${({ theme }) => get(theme, 'blue')};

  ${media.lessThan('sm')`
    font-size: 18px;
    line-height: 1.5;
  `}
`

const Text = styled.span`
  font-family: 'Source Sans Pro';
  font-size: 32px;
  font-weight: bold;
  color: ${({ theme }) => get(theme, 'brightOrange')};
  text-transform: uppercase;

  ${media.lessThan('sm')`
    font-size: 29px;
  `}
`

const IndependantAcquisitionTitle = () => (
  <Container>
    <TitleText>
      Achetez votre local adapté pour les 
    </TitleText>
    <Text>
      PROFESSIONS LIBERALES
    </Text>
  </Container>
)

export default IndependantAcquisitionTitle
