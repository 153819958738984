import React, { useState } from 'react'
import qs from 'qs'
import axios from 'axios'
import publicIp from 'public-ip'
import PropTypes from 'prop-types'
import { nanoid } from 'nanoid'
import { Spinner } from '@styled-icons/fa-solid'
import { useFormik } from 'formik'
import { useLocation } from '@gatsbyjs/reach-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import { get, replace, isEmpty } from 'lodash'
import styled, { keyframes } from 'styled-components'
import * as Yup from 'yup'

import media from '../../../utils/media'
import FormItems from '../../atoms/FormItems'
import FormError from '../../atoms/FormError'
import SubmitButton from '../../atoms/SubmitButton'
import ConfirmationModal from '../../atoms/ConfirmationModal'
import { PHONE_REGEXP } from '../../../config'

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const SpinningIcon = styled(Spinner)`
  margin-right: 0.25rem;
  animation: 2s linear ${spin} infinite;
`

const StyledIcon = styled(FontAwesomeIcon)`
  margin-left: 16px;

  ${media.lessThan('lg')`
    margin-left: 8px;
  `}
`

const formSchema = Yup.object().shape({
  city: Yup.string().required('Une ville de recherche est requise'),
  phone: Yup.string().required('Votre numéro de téléphone est requis').matches(PHONE_REGEXP, 'Votre téléphone n\'est pas au bon format'),
  email: Yup.string().email('Votre email n\'est pas au bon format').required('Votre email est requis'),
  budget: Yup.number().min(10000, 'Nous ne faisons pas de location').required('Votre budget est requis'),
  consent: Yup.bool().required('Veuillez accepter d\'être contacté par Folhomee').oneOf([true], 'Veuillez accepter d\'être contacté par Folhomee'),
  surface: Yup.number().min(6, 'Nous faisons pas de surface aussi petite').required('La surface est requise'),
  lastname: Yup.string().required('Votre nom est requis'),
  firstname: Yup.string().required('Votre prénom est requis'),
  occupation: Yup.string().required('Votre profession est requise')
})

const ITEMS = [{
  type: 'text',
  name: 'firstname',
  placeholder: 'Prénom'
}, {
  type: 'text',
  name: 'lastname',
  placeholder: 'Nom'
}, {
  type: 'text',
  name: 'city',
  placeholder: 'Ville(s) de recherche'
}, {
  type: 'text',
  name: 'occupation',
  placeholder: 'Profession'
}, {
  type: 'text',
  name: 'budget',
  placeholder: 'Budget'
}, {
  type: 'text',
  name: 'surface',
  placeholder: 'Surface minimum'
}, {
  type: 'text',
  name: 'phone',
  placeholder: 'N° de téléphone'
}, {
  type: 'email',
  name: 'email',
  placeholder: 'Email'
}]

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 365px;
`

const CheckBoxContainer = styled.div`
  display: flex;
  align-items: flex-start;
`

const CheckBox = styled.div`
  width: 23px;
  height: 19px;
  margin-top: 0.4rem;
  margin-right: 0.5rem;
  border-radius: 2px;
  border: 1px solid ${({ theme }) => get(theme, 'blue', '#FFF')};
  background-color: ${({ isOk, theme }) => get(theme, isOk ? 'blue' : 'white', '#FFF')};
  cursor: pointer;
`

const CheckText = styled.label`
  font-family: 'Source Sans Pro';
  font-size: 14px;
  line-height: 1.43;
  color: ${({ theme, isWhite }) => get(theme, isWhite ? 'white' : 'blue')};
`

const StyledSubmitButton = styled(SubmitButton)`
  margin: 16px auto;
  width: 75%;
`

const getClientIp = async () => await publicIp.v4({
  fallbackUrls: ['https://ifconfig.co/ip']
})

const IndependantSidebarForm = ({ city, submit, isWhite, hiddenRef }) => {
  const location = useLocation()
  const [show, updateShow] = useState(false)
  const [loading, updateLoading] = useState(false)

  const { values, handleSubmit, handleChange, resetForm, setFieldValue, errors, touched, handleBlur } = useFormik({
    enableReinitialize: true,
    initialValues: {
      city: '',
      email: '',
      phone: '',
      budget: '',
      surface: '',
      consent: false,
      lastname: '',
      firstname: '',
      occupation: ''
    },
    validationSchema: formSchema,
    onSubmit: async values => {
      if (loading) {
        return
      }

      try {
        updateLoading(true)
        const eventId = nanoid()
        const time = Math.floor(Date.now() / 1000)
        const userAgent = navigator.userAgent
        const userIp = await getClientIp()
        const isFacebook = !!get(qs.parse(replace(get(location, 'search'), '?', '')), 'facebook')

        await axios.get(submit, {
          params: {
            ...values,
            time,
            userIp,
            eventId,
            userAgent,
            isFacebook,
            eventSourceUrl: get(location, 'href')
          }
        })

        resetForm()
        updateShow(true)
        trackCustomEvent({
          category: 'send button',
          action: 'click',
          label: 'envoie formulaire'
        })

        if (isFacebook && typeof window.fbq === 'function') {
          window.fbq('track', 'CompleteRegistration', {
            content_name: values.email,
            event_id: eventId,
            event_source_url: get(location, 'href')
          }, {
            eventID: eventId
          })
        }
      } catch (err) {
        console.log(err)
      } finally {
        updateLoading(false)
      }
    }
  })

  return (
    <>
      <StyledForm onSubmit={handleSubmit} id='sideForm'>
        <FormItems
          items={ITEMS}
          errors={errors}
          values={values}
          touched={touched}
          handleBlur={handleBlur}
          handleChange={handleChange}
          setFieldValue={setFieldValue} />
        <CheckBoxContainer
          onClick={() => setFieldValue('consent', !get(values, 'consent'))}>
          <CheckBox
            id='consent-checkbox'
            isOk={get(values, 'consent')}>
            {get(values, 'consent', false) && <FontAwesomeIcon icon='check' color='white' />}
          </CheckBox>
          <CheckText htmlFor='consent-checkbox' error={!isEmpty(get(errors, 'consent'))}>
            J’accepte d’être alerté·e des opportunités immobilières exclusives de Folhomee.
          </CheckText>
        </CheckBoxContainer>
        <FormError error={get(errors, 'consent')} />
        <StyledSubmitButton
          ref={hiddenRef}
          type='submit'
          disabled={loading}>
          {loading &&
            <SpinningIcon
              size={20}
              role='status' />}
          Envoyer <StyledIcon icon='chevron-right' />
        </StyledSubmitButton>
        <ConfirmationModal show={show} updateShow={updateShow}/>
      </StyledForm>
    </>
  )
}

IndependantSidebarForm.propTypes = {
  city: PropTypes.string.isRequired,
  submit: PropTypes.string.isRequired,
  isWhite: PropTypes.bool,
  hiddenRef: PropTypes.object.isRequired
}

IndependantSidebarForm.defaultProps = {
  isWhite: false
}

export default IndependantSidebarForm
